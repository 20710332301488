import { Node } from "PriceMonitoring/modal";

export default function categoryMap(nodes: Node[]): Map<number, Node[]> {
  let data = nodes.map((el) => ({ node: el, deep: 0, parent: [] as Node[] }));
  let result = new Map<number, Node[]>();
  while (data.length) {
    const el = data.pop();
    if (!el) break;
    result.set(el.node.id, [...el.parent, el.node]);
    if (el.node.children?.length) {
      data = [
        ...data,
        ...[...el.node.children].reverse().map((el1) => ({
          node: el1,
          deep: el.deep + 1,
          parent: [...el.parent, el.node],
        })),
      ];
    }
  }
  return result;
}
