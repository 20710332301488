import React, { useMemo, useState } from "react";
import {
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { usePriceMonitoringContext } from "./Contex";
import GroupsListItem from "./GroupsListItem";
import { Space } from "shared/ui";
import { ListTitle, ListWrap } from "./GroupsListStyled";
import { ProductGroup, Node } from "PriceMonitoring/modal";
import { prepareData } from "./createClassifierTreeList";
import { record, read } from "./openGroups";
const GroupsList = (props: { productClassifiers: Node[] }) => {
  const context = usePriceMonitoringContext();
  const [collapse, setCollapsed] = useState<Set<number>>(new Set(read()));

  const handleCollpsed = (id: number) => {
    setCollapsed((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.has(id) ? newSet.delete(id) : newSet.add(id);
      record(Array.from(newSet.values()));
      return newSet;
    });
  };
  const [classifireList, childMap] = useMemo(() => {
    const data = prepareData(props.productClassifiers, collapse, false);
    data.push({
      node: { id: 0, name: "Без категории" },
      indent: 0,
    });
    const data2 = prepareData(props.productClassifiers, new Set([]), true);
    const map = new Map();
    data2.forEach((el) => {
      if (el.node.children?.length) {
        map.set(
          el.node.id,
          el.node.children.map((el) => el.id)
        );
      }
    });

    return [data, map];
  }, [props.productClassifiers, collapse]);
  const groupsByCategory = useMemo(() => {
    const result = context.groups.reduce((acc, el) => {
      const category = el.classifier || { id: 0, name: "Без категории" };
      return {
        ...acc,
        [category.id]: acc[category.id]
          ? { ...acc[category.id], groups: [...acc[category.id].groups, el] }
          : {
              categoryId: category.id,
              categoryName: category.name,
              groups: [el],
            },
      };
    }, {} as Record<number, { categoryId: number; categoryName: string; groups: ProductGroup[] }>);
    return result;
  }, [context.groups]);
  return (
    <ListWrap>
      {classifireList.map((el) => {
        let counter = groupsByCategory[el.node.id]?.groups.length || 0;
        let arr = [...(childMap.get(el.node.id) || [])];
        while (arr.length) {
          const target = arr.pop();
          counter += groupsByCategory[target]?.groups.length || 0;
          const childern = childMap.get(target) || [];
          console.log(childern);
          if (childern) {
            childern.forEach((el: number) => arr.push(el));
          }
        }
        return (
          <>
            <ListTitle onClick={() => handleCollpsed(el.node.id)}>
              {"\u00A0".repeat(el.indent * 2)}{" "}
              {collapse.has(el.node.id) ? (
                <DownOutlined style={{ fontSize: 14 }} />
              ) : (
                <RightOutlined style={{ fontSize: 14 }} />
              )}{" "}
              {el.node.name || "без названия"} ({counter})
            </ListTitle>
            <Space size={8} axis="vertical"></Space>
            {collapse.has(el.node.id)
              ? groupsByCategory[el.node.id]?.groups.map((group) => (
                  <>
                    <GroupsListItem
                      key={el.node.id + " " + group.id}
                      indent={el.indent}
                      data={group}
                      selected={context.selectedGroupId === group.id}
                      edit={context.editGroup}
                      delete={context.removeGroup}
                      changeGroup={context.changeSelectedGroup}
                    />
                    <Space size={8} axis="vertical"></Space>
                  </>
                ))
              : null}
            <Space size={16} axis="vertical"></Space>
          </>
        );
      })}
    </ListWrap>
  );
};

export default GroupsList;
