import { ClassifierProps } from "api/Classifier";
import React, {
  Children,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import { Col, Container, Row, Button, Tabs, Tab } from "react-bootstrap";
import {
  Actions,
  NodeInner,
  NodeSpace,
  NodeWrap,
  Input,
} from "./TreeNodeStyled";
import {
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";

export type Props = {
  space: number;
  title: string;
  id: number;
  collapse: boolean;
  hasChildren: boolean;
  onCollapsed: (id: number) => void;
  onEdit: (props: { id: number; title: string }) => void;
  onCreate: (props: { parentId: number }) => void;
  onRemove: (props: { id: number; title: string }) => void;
};
const TreeNode: React.FunctionComponent<Props> = (props: Props) => {
  const [title, setTitle] = useState(props.title);
  const [edit, setEdit] = useState(false);
  const handleBlur = () => {
    setEdit(false);
    if (!!title.trim().length && title !== props.title) {
      props.onEdit({ ...props, title: title.trim() });
    }
  };
  return (
    <NodeWrap>
      {new Array(props.space).fill(1).map((el) => (
        <NodeSpace> </NodeSpace>
      ))}
      <NodeInner space={props.space} root={false}>
        {props.hasChildren ? (
          props.collapse ? (
            <DownOutlined onClick={() => props.onCollapsed(props.id)} />
          ) : (
            <RightOutlined onClick={() => props.onCollapsed(props.id)} />
          )
        ) : null}
        {edit ? (
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={handleBlur}
            autoFocus
          />
        ) : (
          <span onDoubleClick={() => setEdit(true)}>
            {props.title || "Без названия"}
          </span>
        )}
        <Actions>
          <PlusOutlined
            onClick={() => props.onCreate({ parentId: props.id })}
          />
          {!props.hasChildren && (
            <DeleteOutlined
              onClick={() =>
                props.onRemove({ id: props.id, title: props.title })
              }
            />
          )}
        </Actions>
      </NodeInner>
    </NodeWrap>
  );
};

export default TreeNode;
