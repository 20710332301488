const key = "open_groups_price_editor";
type StorageType = number[];
export const record = (props: StorageType) => {
  localStorage.setItem(key, JSON.stringify(props));
};

export const read = (): StorageType | null => {
  const value = localStorage.getItem(key);
  return value ? (JSON.parse(value) as StorageType) : [];
};
