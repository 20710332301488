import { Node } from "PriceMonitoring/modal";

export const prepareData = (
  nodes: Node[],
  collapsedNodes: Set<number>,
  allOpen: boolean
) => {
  const arr = nodes.map((el) => ({ node: el, indent: 0 }));
  arr.sort((a, b) =>
    a.node.name === b.node.name
      ? a.node.id - b.node.id
      : a.node.name < b.node.name
      ? -1
      : 1
  );
  const result: { node: Node; indent: number }[] = [];
  while (arr.length > 0) {
    const data = arr.pop()!;
    result.push(data);
    const indent = data.indent + 1;

    if (collapsedNodes.has(data.node.id) || allOpen) {
      const children = [...(data.node.children || [])];
      children.sort((a, b) =>
        a.name === b.name ? a.id - b.id : a.name < b.name ? -1 : 1
      );
      children.reverse().forEach((el) => {
        arr.push({ node: el, indent: indent });
      });
    }
  }
  return result;
};
