import styled from "styled-components";

export const ListWrap = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 90px - 32px);
`;

export const ItemWrap = styled.div<{ selected: boolean; indent: number }>`
  padding: 4px 6px;
  border: 1px solid #ccc;
  cursor: pointer;
  ${(props) => props.selected && `border: 2px solid #3aafa9;`}
  &:hover {
    border: 1px solid #3aafa9;
  }
  border-radius: 8px;
  ${(props) => `margin-left: ${14 + props.indent * 8}px`}
`;

export const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ListTitle = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
`;
